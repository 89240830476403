import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "services/ApiService";
import { signOut } from "./authSlice";
import moment from "moment";

export const initialState = {
  orders: [],
  integrationOrders: [],
  loading: false,
  message: "",
  showMessage: false,
  totalPages: 1,
  totalRecords: 0,
  pageSize: 50,
  airports: [],
  fbos: [],
  orderStatus: [],
  caterers: [],
  search: "",
  status: [],
  airport: [],
  fbo: [],
  caterer: "All Caterer's",
  currentPage: 1,
  sortField: "",
  sortOrder: "",
  filter: "",
  startDate: moment().add(-7, "days"),
  endDate: moment().add(7, "days"),
  kitchenContacts: [],
  userViews: [],
};

export const fetchOrders = createAsyncThunk(
  "order/fetchOrders",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await ApiService.getOrders(data);
      if (response.status === 200) {
        return {
          orders: response.data.value,
          airports: response.data["@search.facets"]["airport/icao"].map(
            ({ count, value }) => ({
              key: value,
              value: `${value} (${count})`,
            })
          ),
          fbos: response.data["@search.facets"]["fbo/name"].map(
            ({ value }) => value
          ),
          orderStatus: response.data["@search.facets"].orderStatus.map(
            (item) => item.value
          ),
          caterers: response.data["@search.facets"][
            "supplier/friendlyName"
          ].map((item) => item.value),
          totalRecords: response.data["@odata.count"],
          totalPages: Math.floor(response.data["@odata.count"] / 50),
        };
      } else if (response.status === 401) {
        return rejectWithValue(response.data);
      } else return initialState;
    } catch (err) {
      return rejectWithValue(err.response || "Error");
    }
  }
);

export const fetchIntegrationOrders = createAsyncThunk(
  "order/fetchIntegrationOrders",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await ApiService.getIntegrationOrders(data);
      if (response.status === 200) {
        return {
          integrationOrders: response.data.value,
          totalRecords: response.data["@odata.count"],
          totalPages: Math.floor(response.data["@odata.count"] / 50),
        };
      } else return rejectWithValue(response.data);
    } catch (err) {
      return rejectWithValue({
        success: false,
        message: err.response || "Error",
      });
    }
  }
);

export const fetchKitchenContacts = createAsyncThunk(
  "order/fetchKitchenContacts",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await ApiService.getKitchenContacts();
      if (response.status === 200) {
        return response.data;
      } else return rejectWithValue(response.data);
    } catch (err) {
      return rejectWithValue({
        success: false,
        message: err.response || "Error",
      });
    }
  }
);

export const fetchIntegrationOrderUserView = createAsyncThunk(
  "order/fetchIntegrationOrderUserView",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await ApiService.getIntegrationOrderUserView(data);
      if (response.status === 200) {
        return response.data;
      } else return rejectWithValue(response.data);
    } catch (err) {
      return rejectWithValue({
        success: false,
        message: err.response || "Error",
      });
    }
  }
);

export const saveIntegrationOrderUserView = createAsyncThunk(
  "order/saveIntegrationOrderUserView",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await ApiService.saveIntegrationOrderUserView(data);
      if (response.status === 200) {
        return response.data;
      } else return rejectWithValue(response.data);
    } catch (err) {
      return rejectWithValue({
        success: false,
        message: err.response || "Error",
      });
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.search = action.payload;
    },
    setSelectedStatus: (state, action) => {
      state.status = action.payload;
    },
    setSelectedAirport: (state, action) => {
      state.airport = action.payload;
    },
    setSelectedFbo: (state, action) => {
      state.fbo = action.payload;
    },
    setSelectedCaterer: (state, action) => {
      state.caterer = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setDate: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    clearMessage: (state, action) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        const {
          orders,
          airports,
          fbos,
          orderStatus,
          caterers,
          totalPages,
          totalRecords,
        } = action.payload;
        return {
          ...state,
          loading: false,
          orders,
          airports,
          fbos,
          orderStatus,
          totalPages,
          totalRecords,
          caterers,
        };
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          message: action.payload,
          showMessage: true,
        };
      })
      .addCase(fetchIntegrationOrders.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchIntegrationOrders.fulfilled, (state, action) => {
        const { integrationOrders, totalPages, totalRecords } = action.payload;
        return {
          ...state,
          loading: false,
          integrationOrders,
          totalPages,
          totalRecords,
        };
      })
      .addCase(fetchIntegrationOrders.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          message: action.payload,
          showMessage: true,
        };
      })
      .addCase(fetchKitchenContacts.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchKitchenContacts.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          kitchenContacts: data,
        };
      })
      .addCase(fetchKitchenContacts.rejected, (state, action) => {
        const { message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          showMessage: true,
        };
      })
      .addCase(fetchIntegrationOrderUserView.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchIntegrationOrderUserView.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          userViews: data,
        };
      })
      .addCase(fetchIntegrationOrderUserView.rejected, (state, action) => {
        const { message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          showMessage: true,
        };
      })
      .addCase(saveIntegrationOrderUserView.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(saveIntegrationOrderUserView.fulfilled, (state, action) => {
        const { success, message, data } = action.payload;
        return {
          ...state,
          loading: false,
          userViews: [{
            id: data.id,
            viewId: data.views[0].id,
            name: data.views[0].name,
            type: data.views[0].type,
            addedOn: data.views[0].addedOn,
            columns: data.views[0].columns,
            columnOrdering: data.views[0].columnOrdering,
          }],
          message: message,
          success: success,
        };
      })
      .addCase(saveIntegrationOrderUserView.rejected, (state, action) => {
        const { success, message } = action.payload;
        return {
          ...state,
          loading: false,
          message: message,
          success: success,
        };
      })
      .addCase(signOut.fulfilled, (state) => {
        return initialState;
      });
  },
});

export const {
  clearMessage,
  setSearchText,
  setSelectedStatus,
  setSelectedAirport,
  setSelectedFbo,
  setSelectedCaterer,
  setSelectedPage,
  setSortField,
  setSortOrder,
  setFilter,
  setDate,
} = orderSlice.actions;

export default orderSlice.reducer;
