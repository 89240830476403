import HttpMethod from "http-method-enum";
import { get, http } from "./HttpService";
import {
  ORDERS_INDEX,
  SEARCH_INDEX_API_KEY,
  SEARCH_FACETS,
  PARTNER_API_BASE,
  PORTAL_API_BASE,
  CP_UAM_SUBSCRIPTION_KEY,
} from "../configs/AppConfig";
import moment from "moment";

const ApiService = {};

ApiService.getAirports = async (params) => {
  const { currentPage, pageSize, ...restOfParams } = params;
  const response = await get("airports", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    restOfParams,
  });
  return response;
};

ApiService.searchAirports = async (params) => {
  const { key, value, currentPage, pageSize } = params;
  const response = await get("airports", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    params: {
      filterType: key,
      filterValue: value,
    },
  });
  return response;
};

ApiService.getAirportDetails = async (params) => {
  const { key, value, currentPage, pageSize } = params;
  const response = await get("fbos", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    params: {
      filterType: key,
      filterValue: value,
    },
  });
  return response;
};

ApiService.getMenus = async (params) => {
  const { currentPage, pageSize, partnerId } = params;
  const response = await get("menu", {
    header: {
      page: currentPage,
      "per-page": pageSize,
    },
    params: {
      partnerId: partnerId,
      requires: "externalId",
    },
  });
  return response;
};

ApiService.getOrders = async (params) => {
  const { currentPage, pageSize, search, token, filter, sortField, sortOrder } =
    params;
  let apiUrl = `${PARTNER_API_BASE}/orders?$count=true&${SEARCH_FACETS}`;

  if (search) apiUrl = `${apiUrl}&search=${search}`;
  if (filter) apiUrl = `${apiUrl}&$filter=${filter}`;

  if (sortField) {
    apiUrl = `${apiUrl}&$orderby=${sortField} ${sortOrder}`;
  } else {
    apiUrl = `${apiUrl}&$orderby=deliverydatetime desc`;
  }

  apiUrl = `${apiUrl}&$skip=${(currentPage - 1) * pageSize}&queryType=full`;

  return await http({
    url: apiUrl,
    method: "GET",
    header: {
      "api-key": SEARCH_INDEX_API_KEY,
      Authorization: `Bearer ${token}`,
    },
  });
};

ApiService.getIntegrationOrders = async (params) => {
  const { token } = params;
  let yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

  let apiUrl = `${PARTNER_API_BASE}/orders?$count=true&$filter=((isProcessingStopped eq true and integrationStatus eq 'HighTouch') or (search.in(integrationStatus,'HighTouch,NoTouch') and orderStatus eq 'Draft' and isProcessingStopped ne null)) and (deliverydatetime gt '${yesterdayDate}')&$orderby=utcDeliveryDateTime asc`;

  apiUrl = `${apiUrl}&$skip=0&$top=5000`;

  return await http({
    url: apiUrl,
    method: HttpMethod.GET,
    header: {
      "api-key": SEARCH_INDEX_API_KEY,
      Authorization: `Bearer ${token}`,
    },
  });
};

ApiService.getIntegrationOrderUserView = async (params) => {
  const { userId } = params;
  return await http({
    url: `${PORTAL_API_BASE}/userViews/${userId}`,
    method: HttpMethod.GET,
    header: {
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
  });
};

ApiService.saveIntegrationOrderUserView = async (params) => {
  return await http({
    url: `${PORTAL_API_BASE}/userViews`,
    method: HttpMethod.POST,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    body: params,
  });
};

ApiService.getCustomers = async () => {
  return await http({
    url: `${PORTAL_API_BASE}/customer`,
    method: HttpMethod.GET,
    header: {
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
  });
};

ApiService.addCustomer = async (params) => {
  return await http({
    url: `${PORTAL_API_BASE}/customer`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.POST,
    body: params,
  });
};

ApiService.editCustomer = async (params) => {
  const { name, clientId, customerPartyIds, customerId, type } = params;
  return await http({
    url: `${PORTAL_API_BASE}/customer/${customerId}`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.PUT,
    body: {
      name,
      clientId,
      customerPartyIds,
      type,
    },
  });
};

ApiService.deleteCustomer = async (params) => {
  const { customerId } = params;
  return await http({
    url: `${PORTAL_API_BASE}/customer/${customerId}`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.DELETE,
  });
};

ApiService.getUsers = async (params) => {
  const { customerId } = params;
  return await http({
    url: `${PORTAL_API_BASE}/customer/${customerId}/user`,
    method: HttpMethod.GET,
    header: {
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
  });
};

ApiService.addUser = async (params) => {
  const {
    customerId,
    firstName,
    lastName,
    email,
    role,
    fbos,
    connectToBC,
    active,
  } = params;
  return await http({
    url: `${PORTAL_API_BASE}/customer/${customerId}/user`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.POST,
    body: {
      firstName,
      lastName,
      email,
      role,
      fbos,
      connectToBC,
      active,
    },
  });
};

ApiService.activateUser = async (params) => {
  const { userId } = params;
  return await http({
    url: `${PORTAL_API_BASE}/customer/user/${userId}/activate`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.POST,
  });
};

ApiService.editUser = async (params) => {
  const { firstName, lastName, customerId, userId, role, fbos, connectToBC } =
    params;
  return await http({
    url: `${PORTAL_API_BASE}/customer/${customerId}/user/${userId}`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.PUT,
    body: {
      firstName,
      lastName,
      role,
      fbos,
      connectToBC,
    },
  });
};

ApiService.deleteUser = async (params) => {
  const { customerId, userId } = params;
  return await http({
    url: `${PORTAL_API_BASE}/customer/${customerId}/user/${userId}`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.DELETE,
  });
};

ApiService.getPermissions = async () => {
  return await http({
    url: `${PORTAL_API_BASE}/permissions`,
    method: HttpMethod.GET,
    header: {
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
  });
};

ApiService.getRoles = async (params) => {
  const { type } = params;
  return await http({
    url: `${PORTAL_API_BASE}/roles/${type}`,
    method: HttpMethod.GET,
    header: {
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
  });
};

ApiService.addRole = async (params) => {
  const { name, description, permissions, type } = params;
  return await http({
    url: `${PORTAL_API_BASE}/roles`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.POST,
    body: {
      name,
      description,
      permissions,
      type,
    },
  });
};

ApiService.editRole = async (params) => {
  const { name, description, permissions, roleId, type } = params;
  return await http({
    url: `${PORTAL_API_BASE}/roles/${roleId}`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.PUT,
    body: {
      name,
      description,
      permissions,
      type,
    },
  });
};

ApiService.deleteRole = async (params) => {
  const { roleId } = params;
  return await http({
    url: `${PORTAL_API_BASE}/roles/${roleId}`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.DELETE,
  });
};

ApiService.getInvoices = function (params) {
  const data = fetch({
    url: "/invoices",
    method: "get",
    params,
  });
  return data.invoices;
};

ApiService.forgotPassword = async (params) => {
  const { email } = params;
  return await http({
    url: `${PORTAL_API_BASE}/forgot-password`,
    header: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
    method: HttpMethod.POST,
    body: {
      email,
    },
  });
};

ApiService.getKitchenContacts = async () => {
  return await http({
    url: `${PORTAL_API_BASE}/kitchenContact`,
    method: HttpMethod.GET,
    header: {
      "Ocp-Apim-Subscription-Key": CP_UAM_SUBSCRIPTION_KEY,
    },
  });
};

export default ApiService;
